<template>
  <div class="right_connent page_setting">
    <div class="title">修改密码</div>
    <div class="connent">
      <el-form
        :model="verification"
        :rules="verification_rules"
        ref="verification_ruleForm"
      >
        <el-form-item
          prop="mobile"
          label="手 机 号："
          :label-position="labelPosition"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入注册手机号"
            v-model="verification.mobile"
            style="width: 540px"
            clearable
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="验  证  码："
          :label-position="labelPosition"
          :label-width="formLabelWidth"
          prop="code"
        >
          <el-input
            placeholder="请输入短验证码"
            style="width: 540px"
            v-model="verification.code"
          >
            <el-button
              class="get_code"
              slot="suffix"
              type="text"
              style="margin-right: 10px"
              @click="get_code()"
              >{{
                !codeTime ? "获取验证码" : codeTime + "s" + "后获取"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item
          label="新  密  码："
          :label-position="labelPosition"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            placeholder="请输入新密码"
            v-model="verification.password"
            show-password
            style="width: 540px"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-unlock"
              style="color: #c7dffe"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item
          label="再次输入："
          :label-position="labelPosition"
          :label-width="formLabelWidth"
          prop="password2"
        >
          <el-input
            placeholder="请再次输入新密码："
            v-model="verification.password2"
            show-password
            style="width: 540px"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-unlock"
              style="color: #c7dffe"
            ></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button type="primary" @click="onSubmit('verification_ruleForm')"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写手机号"));
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    var checkPass = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写密码"));
      } else {
        callback();
      }
    };
    var checkPass2 = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.verification.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写验证码"));
      } else {
        callback();
      }
    };

    return {
      formLabelWidth: "120px",
      labelPosition: "left",
      verification: {
        mobile: "",
        code: "",
        password: "",
        password2: "",
      },
      verification_rules: {
        mobile: [{ validator: checkPhone, trigger: "blur", required: true }],
        code: [{ validator: checkCode, trigger: "blur", required: true }],
        password: [{ validator: checkPass, trigger: "blur", required: true }],
        password2: [{ validator: checkPass2, trigger: "blur", required: true }],
      },
      codeTime: 0,
    };
  },
  created() {},
  methods: {
    // 触发验证码
    get_code(type) {
      this.send_sms_type = type;
      var m = "";
      m = this.verification.mobile;

      if (!m) {
        this.$message({
          message: "请填写手机号",
          type: "warning",
        });
        return;
      }
      if (!/^(1[0-9])\d{9}$/.test(m)) {
        this.$message({
          message: "手机号码有误，请重填",
          type: "warning",
        });
        return;
      }
      this.code_last();
    },
    // 倒计时
    code_last() {
      if (this.codeTime > 0) {
        this.$message({
          showClose: true,
          message: "请在" + this.codeTime + "秒后获取",
          type: "warning",
        });
        return;
      } else {
        this.tosend_sms();
        this.codeTime = 60;
        let timer = setInterval(() => {
          this.codeTime--;
          if (this.codeTime < 1) {
            clearInterval(timer);
            this.codeTime = 0;
          }
        }, 1000);
      }
    },
    async tosend_sms() {
      try {
        let params = {};
        params.type = 2; //忘记密码type:2
        params.mobile = this.verification.mobile;
        let res = await api.send_sms(params);
        if (res.code == 0) {
          this.$message({
            message: "验证码已发送",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async to_reset_pwd() {
      try {
        let params = {
          mobile: this.verification.mobile,
          code: this.verification.code,
          enter_pwd: this.verification.password,
          new_pwd: this.verification.password2,
        };
        let res = await api.reset_pwd(params);
        if (res.code == 0) {
          this.$message({
            message: "密码修改成功",
            type: "success",
          });
          this.verification = {
            mobile: "",
            code: "",
            password: "",
            password2: "",
          };
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    onSubmit(verification_ruleForm) {
      this.$refs[verification_ruleForm].validate((valid) => {
        if (valid) {
          //  console.log(this.addressform);
          setTimeout(() => {
            this.to_reset_pwd();
          }, 500);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      setTimeout(() => {
        this.$refs[verification_ruleForm].clearValidate();
      }, 2000);
    },
  },
  component() {},
};
</script>
<style>
.page_setting .el-form-item__label {
  font-size: 16px;
  
  font-weight: 400;
  color: #333333;
}

.page_setting .btn {
  margin: 0 auto;
  text-align: center;
}
.page_setting .btn .el-button {
  width: 160px;
  height: 40px;
  /* line-height: 40px; */
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  /* margin: 0 auto; */
  margin-top: 100px;
  color: #ffffff;
  text-align: center;
}
</style>


<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
    // border-bottom: 1px solid #e9e9e9;
  }
}
</style>